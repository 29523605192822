import { render, staticRenderFns } from "./index.vue?vue&type=template&id=35c9a878&lang=pug&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.9.7_0aadd186e864c83cab1284bae568d6ae/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextLeftImageRight: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/text-left-image-right.vue').default,OneTextCenter: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/one-text-center.vue').default,HorizontalImages: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/horizontal-images.vue').default,SeveralTextCenter: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/several-text-center.vue').default,TwoBlocks: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/two-blocks.vue').default,ThreeColumnsTwoRows: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/three-columns-two-rows.vue').default,ThreeColumnsAndText: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/three-columns-and-text.vue').default,Testimonials: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/testimonials.vue').default})
