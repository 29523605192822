//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
    	trusted: Boolean
  }
}
