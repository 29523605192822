//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



import textLeftImageRight from '../components/text-left-image-right'
import oneTextCenter from '../components/one-text-center'
import horizontalImages from '../components/horizontal-images'
import severalTextCenter from '../components/several-text-center'
import twoBlocks from '../components/two-blocks'
import treeColumnsTwoRows from '../components/three-columns-two-rows'
import treeColumnsAndText from '../components/three-columns-and-text'
import treeColumns from '../components/three-columns'
import testimonials from '../components/testimonials'
import textLeftImageRightVarTwo from '../components/txt-left-img-right-variation-02'
import severalTextCenterTwo from '../components/several-text-center-variation-02'
export default {
  data () {
    return {
      title: 'Januxmedia - a full-service software development agency.',
      loaded: null,
      firstTime: null
    }
  },
  head() {
    return {
      title: this.title,
      meta: [
        {
          hid: 'JanuxMedia Home',
          name: 'JanuxMedia Home',
          content: 'Meet your critical business objectives by outsourcing your UI/UX and java and node.js software development efforts to our full service agency'
        }
      ]
    }
  },
  name: 'indexPage',
  components: {
   textLeftImageRight,
   oneTextCenter,
   horizontalImages,
   severalTextCenter,
   twoBlocks,
   treeColumnsTwoRows,
   treeColumnsAndText,
   treeColumns,
   testimonials,
   textLeftImageRightVarTwo,
   severalTextCenterTwo
  },
  created () {
    this.loaded = true
  }
}
